$bg : #14121f;
$blue : #64CAFF;
$cream : #FEFFF5;

$breakpoints: (
  "mobile"    "(max-width: 745px)",
  "tablet"    "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "tablet-down" "(max-width: 1023px)",
  "desktop"   "(min-width: 1024px)",
  "desktop-plus"      "(min-width: 1200px)",
  "wide"      "(min-width: 1400px)",
  "max"      "(min-width: 2000px)",
  "shallow"    "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"   "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)",
  "landscape" "(orientation: landscape)"
) ;

$system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


$base-type : 20px;
$base-line : 1.5;
$large-type: 28px;
$large-line : 1.4;

$large-type-mobile : $large-type * 0.95;

@mixin mediumType(){

    font-size: 3vh;

    @include respond-to('portrait'){
      font-size:24px;
    }

    @include respond-to('mobile'){
      font-size:22px;
    }


}

@font-face {
    font-family:"Castling";
    src:url("font/Castling/CastlingBetav1.003-Regular.woff2") format("woff2"),url("font/Castling/CastlingBetav1.003-Regular.woff") format("woff");
    font-style:normal;font-weight:400;
}

@import "@moxy/react-animate-text/dist/index.css";

/*
  
  SASS Imports

*/

@import './sass/_mixins.scss';
@import './sass/_animations.scss';


* {
  box-sizing: border-box;
}


html{
  background:$bg;
  color:$cream;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  /*font-family:  helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;*/
    font-family:'Castling',serif;
    font-size:$base-type;
    line-height:$base-line;
  
}

img{
  width:auto;
  max-width:100%;
}

/*

Splash (pre app)

*/


#splash{
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%,-60%);
  z-index:5;
  text-align:center;
  width:65%;
  opacity:0;
  animation:fadeInOut 1.5s 0.2s ease forwards;
  pointer-events:none;
  display:none;
}

#splash *{
  font-size:4vh;/* Mobile */
  font-size:5vw;
  font-weight:normal;
  text-transform: uppercase;
  letter-spacing:0.01em;
}

#splash h1{
  display:inline-block;
  border-bottom:0.05em solid;
  line-height:1;
}

/*

Root

*/

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  display:block;
  background: #000;
  background:#070321;
  background:#050316;
  background:#14121f;
  color:white;
  color:#FEFFF5;
   transition:background 0.5s ease;
   will-change:background;
}

.Tooltip{

  position:fixed;
  pointer-events:none;
  z-index:5;
}

.Tooltip-caption{
  font-family:$system-font;
  background:lighten($bg,3%);
  padding:10px;
  font-size:11px;
  white-space: nowrap;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(10%,100%);
  border-radius:10px;
  opacity:0;
  animation:fadeInOutPulse 4s 1s ease forwards;
  pointer-events:none;
}

.Tooltip-touch-caption{
  font-family:$system-font;
  z-index:1;
  background:lighten($bg,3%);
  padding:10px;
  font-size:11px;
  white-space: nowrap;
  position:fixed;
  bottom:10vh;
  left:50%;
  transform:translate(-50%,0);
  border-radius:10px;
  opacity:0;
  animation:fadeInOutPulse 4s 1s ease forwards;
  pointer-events:none;
}


/*

App elements

*/

.Prismic-loader{
  display:block;
  position:fixed;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  padding:3px;
  text-align:center;
  transition:opacity 1s ease;
  /*font-family:$system-font;*/
  
  border-bottom:1px solid;
  color:rgba($cream,0);

  &:after{
    content:'';
    display:block;
    width:100%;
    display:block;
    bottom:0;
    left:0;
    transform: scaleX(0);
    border-bottom:1px solid $cream;
    animation:progress 1s ease forwards;
  }

  /*padding:10px 20px;
  background:lighten($bg,3%);
  border-radius:30px;*/

}

.Prismic-loader--loaded{
  opacity:0;
}

.Prismic-content{
  opacity:0;
  transition:opacity 1s ease;
}

.Prismic-content--loaded{
  opacity:1;
}

.grad{
  content:'';
  position:fixed;
  z-index:2;
  top:0;
  left:0;
  right:0;
  height:10vw;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  transition:background 0.2s ease;
   will-change:background;
}



.App-title,
.App-nav,
.App-cta,
.App-foot{
  position:fixed;
  z-index:3;
  padding:0;
  margin:0;
  /*letter-spacing:-0.03em;*/
  transition:opacity 1s 0.4s ease;
  opacity:0;

  @include respond-to('mobile'){


    *{
      color:black;
      
    }

    &.App-title,
    &.App-nav,
    &.App-cta{
      font-size:$large-type * 1.2;
      letter-spacing:-0.03em;
    }
   }
}

.App-content--showNav .App-nav,
.App-content--showNav .App-cta,
.App-content--showNav .App-foot{
  opacity:1;


   @include respond-to('mobile'){
    opacity:0;
    pointer-events:none;

   }
}

.App-content--showMenu{
  .App-nav,.App-cta,.App-foot{

   @include respond-to('mobile'){
    transition:opacity 0.4s 0.3s ease;
    opacity:1;
    pointer-events:all;
   }
 }
}

.App-content--hideMenu{
  .App-nav,.App-cta,.App-foot{

   @include respond-to('mobile'){
    transition:opacity 0.4s ease;
   }
 }
}

.App-burger{

    display:none;
   @include respond-to('mobile'){
    display:block;
    position:fixed;
    top:15px;
    left:10px;
    display:inline-block;
    //background: rgba($bg,0.9);
    padding:5px 10px;
    border-radius:30px;
    z-index:4;


    body.page--light &{
      color:$bg;
      //background:rgba($cream,0.9);
    }

    &.App-burger--open{
        
        transition:opacity 0.4s 0s ease;

        .App-content--showMenu &{
          transition:opacity 0.4s ease;
          opacity:0;
          pointer-events:none;
        }

    }
    

    &.App-burger--close{
      color:$bg!important;
      //background:rgba($cream,0.9)!important;
      right:10px;
      left:auto;
      pointer-events:none;
      transition:opacity 0.4s ease;
      opacity:0;

        .App-content--showMenu &{
          transition:opacity 1s 0.4s ease;
          opacity:1;
          pointer-events:all;

        }

    }
   }
 }

.App-content{
  &::before{
  content:'';
  background:$cream;
  background:rgba($cream,1);
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:2;
  pointer-events:none;
  display:none;
  transition:opacity 0.4s ease;
    @include respond-to('mobile'){
      display:block;
      opacity:0;

    }
  }

  &.App-content--showMenu::before{
     @include respond-to('mobile'){
      display:block;
      opacity:1;

    }
  }

  &.App-content--hideMenu::before{

      transition:opacity 0.4s ease;
      opacity:0;

  }

}

.App-title,.App-nav,.App-foot{
  font-weight:normal;
  /*font-size:35px;
  font-size:3vh;
  font-size:16px;*/
  padding:20px;
  /*line-height:1.1;*/
}

.App-content{
  animation:fadeIn 1s ease forwards;
}

.App-title{
  top:0;
  left:0;
  display:none;
}

.App-nav{
  top:0;
  left:0;
  width:auto;
  list-style:none;
  text-align:left;
}

.App-nav li{
  list-style:none;
  display:block;
  padding-left:0px;

  &:last-child{
    padding-top:0.5em;
  }
}

.App-foot{
  bottom:0;
  left:0;
  width:auto;
  list-style:none;
  text-align:left;
}

.App-foot li{
  list-style:none;
  display:inline-block;
  padding-left:0px;
}

.App-cta{
  bottom:30px;
  right:30px;

  @include respond-to('mobile'){
    bottom:20%;
  }
}

a{
  color:white;
  color:#FEFFF5;
  transition:opacity 0.5s ease;
  text-decoration:none;
}


.no-touch a:hover,
a.active{
  opacity:0.5;
}

.App-nav a,
.App-foot a{
  opacity:0.5;
}

.no-touch .App-nav a:hover,
.App-nav a.active,
.no-touch .App-foot a:hover,
.App-foot a.active{
  opacity:1;
}



body.page--light,
body.page--light a,
body.page--act,
body.page--act a{
  color:black;
}

body.page--light .grad{

   background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);

}

body.page--light #root{
  background:white;
  background:$cream;
}

body.page--act #root{
  background:$blue;
}

body.page--act .App-nav a,
body.page--act .App-foot a{
  
  opacity:1;

}
body.page--act .App-cta{
  opacity:0;
}

/*

Intro

*/

.Intro-wrap{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  transform-origin: 50% 50%;
  @include responsive-cursor("/img/cursor-skip",15,15);
}

.Intro{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-70%);
  width:90%;
  text-align:left;

  @include respond-to('mobile'){

    top:60px;
    transform:translate(-50%,0%);

  }

  
  /*top:0;
  left:0vw;
  transform: none;*/

}

.Intro p{
  @include mediumType();
  max-width:700px;
  margin:1em auto;
  opacity:0;
  animation:fadeIn 2s 3s ease forwards;
}

.Intro p:first-child{
  animation:fadeIn 3s 0.5s ease forwards;
}

.Intro-cta{
  opacity:0;
  animation:fadeIn 2s 5s ease forwards;
  max-width:700px;
  margin:0 auto;
}

.Intro-cta span{
  display:inline-block;
  opacity:0.5;
  cursor:pointer;
  transition:opacity 0.4s ease;
}

.no-touch .Intro-cta span:hover{
  opacity:1;
}

/*

Links

*/


.Link--cta{
  display:flex;
  width:120px;
  height:120px;
  background:$blue;
  color:black;
  font-weight:bold;
  text-align:center;
  text-transform:uppercase;
  border-radius:60px;
  font-size:30px;
  line-height:0.9;
  letter-spacing:-0.05em;
  transition:transform 0.4s ease;
  align-items: center;
   transform:scale(1) rotate(-20deg);
}

.Link--cta span{
  position:absolute;
  top:50%;
  left:0;
  width:100%;
  transform: translateY(-50%);
  
}

.no-touch .Link--cta:hover{
  transform:scale(1.1) rotate(-5deg);
  opacity:1;
}

/*

Pages

*/

.Index,.Basic,.Act{
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  position:fixed;
  top:0%;
  left:0%;
  right:0;
  bottom:0;
  z-index:1;
  padding:20px 20px;
  padding-bottom:15vh;
  padding-left:20vw;
  background:transparent;
   animation:fadeIn 0.8s ease forwards;


   @include respond-to('mobile'){
    padding:90px 20px 40px 20px;

    &.Index{
      padding-left:0;
      padding-right:0;
    }
   }
}

.Index-item{
  display:block;
  padding-bottom:8px;
  /*font-size:16px;
  font-size:36px;
  font-size:3vh;
  font-size:16px;*/
  border-bottom:1px solid rgba(255,255,255,1);
  margin-bottom:8px;
  /*letter-spacing:-0.03em;*/

    @include respond-to('mobile'){
      padding:5px 20px;
    }

}

.Index-content > div:last-child .Index-item{
  border-bottom:none;
}

.Index-item > div{
  display:inline-block;
  width:30%;
  vertical-align:top;
  max-width:300px;
  padding-right:20px;

  @include respond-to('mobile'){
    width:100%;
    padding-bottom:0.5em;
  }
}

.Index-item > div:last-child{
  width:50%;
  max-width:600px;

  @include respond-to('mobile'){
    width:100%;
    padding-bottom:0.5em;
  }
}

.Basic,.Act{
  
  color:black;

}

.Basic a,.Act a{
  color:black;
  border-bottom:1px solid;
}

.Basic-content,.Act-content{
  /*font-size:35px;
  letter-spacing:-0.03em;*/
  width:90%;
  max-width:600px;
}

.Basic p:first-child{
  margin-top:0;
}

.Basic .Basic-content p{
  font-size:$base-type;
}

.Basic--about .Basic-content p{
  @include mediumType();
  width:auto;
}

.Basic--act .Basic-content p:first-child{
  font-size:$base-type;
}

.Basic-cols{

  margin-left:-40px;
  & > div{
    width:50%;
    display:inline-block;
    vertical-align:top;
    padding-left:40px;

    @include respond-to('mobile'){
      width:100%;
    }
  } 
}

.Basic--act{
  .Basic-content{
    max-width:800px;
    max-width:none;
    width:80%;
  }
}

.Basic,.Index{

  @include respond-to('mobile'){
    &:after{
      content:'';
      display:block;
      position:fixed;
      width:100%;
      top:0;
      left:0;
      height:90px;
      background: linear-gradient(0deg, rgba(254,255,245,0) 0%, rgba(254,255,245,1) 100%);
      z-index:3;
    }
  }
}

.Basic--act{
  @include respond-to('mobile'){
      &:after{
        background: linear-gradient(0deg, rgba($blue,0) 0%, rgba($blue,1) 100%);
      }
    }
}

.Index{

    @include respond-to('mobile'){
      &:after{
        background: linear-gradient(0deg, rgba($bg,0) 0%, rgba($bg,1) 100%);
      }
    }

}

.Basic--act{

}

/*

Product

*/

.Product{
  padding:20px 0;
  max-width:200px;
  position:relative;
  cursor:pointer;
  margin-right:auto;
  margin-left:auto;

}
.no-touch .Product:hover .Product-media{
  transform:rotate(10deg) scale(1.05);
  box-shadow:20px 20px 20px rgba(0,0,0,0.2);
}
.Product-media{
  box-shadow:5px 5px 10px rgba(0,0,0,0.5);
  transition: all 0.4s ease;
  display:block;
  transform:rotate(10deg);

  &:before{
    content:'';
    padding-top:500/309 * 100%;
    display:block;
    background:#2a2a2c;
  }
}

.Product-media img{
  width:100%;
  display:block;
  position:absolute;
  top:0;
  left:0;
  display:block;
  opacity:0;
  animation:fadeIn 0.6s 1s ease forwards;
}

.Product-button{
  background:white;
  width:100px;
  height:100px;
  border-radius:50px;
  position: absolute;
  bottom:0;
  right:0;
  font-weight:bold;
}

.Product-button span{

  display:block;
  width:100%;
  position:absolute;
  top:50%;
  left:0;
  transform: translate(0,-50%);
  text-align: center;
  /*text-transform: uppercase;*/
  font-weight:normal;

}

.Product-submit{
  position:absolute;
  background:transparent;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  display: block;
  border:none;
  outline:none;
  margin:0;
  padding:0;
  cursor:pointer;

}

/*

Paper

*/

.Paper{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba($bg,0.8);
  /*backdrop-filter:blur(4px);*/
  z-index:2;
 
  @include respond-to('mobile'){
    z-index:4;
  }
}

.Paper-scroll{
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  position:absolute;
  top:0%;
  left:0%;
  right:0;
  bottom:0;
  z-index:2;

   padding:10vh 0 20vh 0;

   @include respond-to('mobile'){
    padding:0;
   }
}

@keyframes slideUp{
  0%{
    transform: translate3d(0,40px,0);
  }
  100%{
    transform: translate3d(0,0px,0);
  }
}

@keyframes progress{
  0%{
    transform: scaleX(0);
  }
  100%{
   transform: scaleX(1);
  }
}

.Paper-content{
  background:white;
  background:$cream;
  box-shadow:0 0 10px rgba(0,0,0,0.5);
  border-radius: 5px;
  width:90%;
  margin:0 auto;
  margin-left: 15%;
  max-width:700px;
  padding:5vw 5vw;
  position: relative;
  z-index:2;
  pointer-events:all;
  color:black;
  animation:slideUp 1s ease forwards;

  @include respond-to('tablet'){
    margin-left:auto;
    margin-right:auto;
    width:80%;
    margin-right:2%;
    margin-left:18%;
  }
  @include respond-to('mobile'){
    animation:fadeIn 1s ease forwards;
    width:auto;
    margin:0;
    border-radius:0;
    padding:60px 20px;

    &:after{
      content:'';
      display:block;
      position:fixed;
      width:100%;
      top:0;
      left:0;
      height:90px;

      background: linear-gradient(0deg, rgba(254,255,245,0) 0%, rgba(254,255,245,1) 100%);
      z-index:3;
    }
   }

}

.Paper-back{
  display:block;
  width:80px;
  height:80px;
  background:$bg;
  margin:60px auto;
  margin-bottom:0;
  border-radius:50%;
  position: relative;

 

}

.Paper-back span{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  display:inline-block;
  /*text-transform: uppercase;
  font-size:12px;
  letter-spacing:0.1em;*/
    opacity:0.9;
}

.Paper-share{
  text-align:center;
  font-size:10px;
  /*text-transform:uppercase;*/
  letter-spacing:0.01em;
  padding-top:10vh;
  font-family:sans-serif;
}

.Paper-share p{
  opacity:0.5;
  padding:0;
  margin:0;
  display:none;
}

.Paper-share > *{
  padding:10px;
}

.Paper-share button{
  margin:8px;
  display:inline-block;
}

.no-touch .Paper-share button:hover{
  opacity:0.5;
}

.Paper-share button:focus{
  outline:none;
  border:none;
}

.Paper-share circle{
  stroke:rgba(0,0,0,0.2);
} 

.Paper-close{
  position:absolute;
  top:0%;
  left:0%;
  right:0;
  min-height:150vh;
  display:block;
  cursor:alias;
  z-index:1;
  background:transparent;

  @include respond-to('mobile'){
    position:fixed;
    height:auto;
    background:transparent;
    z-index:3;
    color:black;
    left:auto;
    padding:20px;
    &:after{
      content:'Close'
    }
   }
}
.Paper-close span{
  display:none;

}

.Paper-head{
  padding:2vh 0;
}

.Paper-head span{
  opacity:0.6;
}


.Paper-body p{

}

.Paper-body p:first-child{
  font-size:$large-type;
  line-height:$large-line;
  padding-bottom:2vh;
  padding-right:15%;
  position:relative;
  &:before{
    content:"\201C";
    display:inline-block;
    position:absolute;
    top:0;
    left:-0.4em;
  }
  &:after{
    content:"\201D"
  }


   @include respond-to('mobile'){
    font-size:$large-type-mobile;
    padding-right:0;
    margin-left:10px;
    margin-right:10px;
   }

}

/*

Quotes Wall

*/

[data-draggable]{
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;

}

.Quotes{
  top:0;
  left:0;
  bottom:0;
  right:0;
  position:fixed;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  //cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 39 39, auto;
  cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    transform:scale3d(0.99,0.99,1);
    opacity:0;
  animation:scaleIn 0.8s 0.2s ease forwards;
}

.Quotes--swiper{
  position:fixed;
  opacity:0;
  animation:scaleIn 0.8s 0.2s ease forwards;
  top:0;
  left:0;
  bottom:0;
  right:0;

  .swiper-container{
    height:100%;
  }

  .swiper-wrapper{
    height:100%;
  }
}





.Quotes::after{
  content:'';
  display:block;
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
   background: radial-gradient(circle, rgba($bg,0) 50%, rgba($bg,1) 100%);
  pointer-events: none;
}

.Quotes-wrapper{
  width:200vw;
  position:absolute;
  top:0;
  left:0;
}

.Quote{
  
  @include mediumType();
  opacity:0.5;
  display:inline-block;
  vertical-align:middle;
  transition:opacity 1s ease;
  line-height:1.2;
  line-height:$large-line;
  display:flex;
  align-items: center;
  height:100%;
  padding:20px;
  padding-top:0;

  position: relative;
  z-index:2;

  @include respond-to('tablet-up'){
    .is-touch &{
      opacity:1;
    }
  }
  

  @include respond-to('mobile'){
    opacity:0;

    .swiper-slide-active &{
      opacity:1;
    }
  }
}

.Quote > div > p{
  position:relative;
  margin-bottom:0.5em;
}

.Quote > div > p:before{
  content:"\201C";
  display:inline-block;
  position:absolute;
  top:0;
  left:-0.4em;
}
.Quote > div > p:after{
  content:"\201D"
}

.Quote > div{
  animation:fadeIn 1s ease forwards;
}

.Quote a,
.Quote-foot{
  font-size:2vh;
  /*font-family:  helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,sans-serif;*/
  /*font-size:16px;*/
  padding: 5px 0;
  line-height:1.1;

}

.Quote-foot{
  opacity:0;
  transition:opacity 1s ease;
  .is-touch &{
    opacity:1;
  }
}

.Quote a{
  /*font-size:16px;*/
  font-size:$base-type;
  opacity:0.5;
  display:inline-block;
}

[data-col-ix="even"] .Quote{
  transform: translateY(50%);
}


[data-col-ix="even"][data-row-ix="even"] .Quote{
  transform: translate(50%,50%);
  
}

[data-col-ix="odd"][data-row-ix="odd"] .Quote{
  transform: translate(10%,0%);
  
}

[data-col-ix="odd"][data-row-ix="even"] .Quote{
  transform: translate(-20%,-20%);
  
}

.no-touch .Quote:hover{
  opacity:1;
}

.no-touch .Quote:hover .Quote-foot{
  opacity:1;
}


